import React, { useState, useEffect } from "react";
import Banner from "../../components/banner/banner";
import club from "../../assets/images/club.png";
import "react-responsive-modal/styles.css";
import { Modal as ModalC } from "react-responsive-modal";
import "./index.scss";
import { useHistory } from "react-router-dom";
import { hideLoader, showLoader } from "../../components/loader";
import { axiosCalls } from "../../components/_api";
import { AuthContext } from "../../context/AuthContext";
import { PaystackButton } from "react-paystack";
import { NotificationManager } from "react-notifications";

const Index = (props) => {
  const history = useHistory();
  const { user, setUser, setModal, Modal } = React.useContext(AuthContext);
  const ticketId = history.location.pathname.split("/")[2];
  const [count, setCount] = useState("");
  const [gate, setGate] = useState("");
  const [gates, setGates] = useState([]);
  const [packages, setPackage] = useState("");
  const [packageName, setPackageName] = useState("");
  const [tickets, setTicket] = useState([]);
  const [ticketsCL, setTicketCL] = useState([]);
  const [singleTicketAmount, setSingleTicketAmount] = useState(0);
  const [User, setUserC] = useState();
  const [em, setEm] = useState("");
  const [open, setOpen] = useState(false);
  const [ticketDetails, setTicketDetails] = useState({});
  console.log(ticketId);
  useEffect(() => {
    makeCalls();
  }, []);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  useEffect(() => {
    console.log(User);
    setUserC(user);
    getEmail();
  }, [user]);

  const getticket = () => {
    const res = axiosCalls(`fixture/${ticketId}`, "GET");
    if (res) {
      if (res.er) {
        return;
      }
      return res;
    }
  };

  const handleOnSuccess = (e) => {
    console.log(e);
    // Call payment buy ticket api
    // access transaction ID using e.trxref or e.reference

    submitTicket(e);
  };

  const handleOnClose = () => {
    // Optional
    // Do any custom action like show modal or log cart
    NotificationManager.error("Payment error", "Please retry again");
  };

  const getGate = (id) => {
    let filterData = ticketsCL.filter((data) => {
      return data._id == id;
    });
    console.log(filterData[0]);
    setTicketDetails(filterData[0]);
    setGates(filterData[0].gates);
    setPackageName(filterData[0].name);
    setSingleTicketAmount(filterData[0].price);
  };

  const makeCalls = async () => {
    showLoader();
    const [ticket] = await Promise.all([getticket()]);
    hideLoader();
    console.log(ticket);
    if (ticket.status == "success") {
      setTicket(ticket?.payload);
      setTicketCL(ticket.payload.ticket_classes);
    }
  };

  const makePayment = () => {
    // MAKE PAYMENT
  };

  const getEmail = () => {
    let res = localStorage.getItem("user");
    if (res == null || res == undefined || res == "") {
      return;
    }
    let st = JSON.parse(res);
    console.log("EMAIL>>>>>>>>>>>>>", st);
    setEm(st.email);
    return st.email;
  };

  const submitTicket = async (e) => {
    console.log(e);

    const data = {
      trnx: e.trxref,
      ticket: ticketDetails._id,
      gate: gate,
      quantity: count,
    };

    showLoader();
    const res = await axiosCalls("ticket", "POST", data);
    hideLoader();
    if (res) {
      console.log(res);
      if (res.er) {
        return;
      }
      console.log(res);
      onOpenModal();
      return NotificationManager.success(res.message);
    }
  };

  return (
    <div>
      <Banner
        title="Buy Match Ticket"
        body="Purchase matches tickets for upcoming matches and tournaments."
      />

      <div className="ticketTable app-padding">
        <div className="ticketTableHead">
          <h2>Ticket purchase</h2>
        </div>

        <div className="tbajd">
          <div className="ticketSum">
            <div className="ticketSumCol1">
              <h2>CHOOSE YOUR TICKET</h2>
              <p>
                {/* {tickets.length == 0
                  ? ""
                  : ` ${tickets?.teams[0]?.name} VS ${tickets?.teams[1]?.name}
                  ${tickets?.starts}, ${tickets?.venue}`
                  } */}

{tickets.length == 0
                  ? ""
                  : `
                  ${(new Date(tickets?.starts)).toUTCString()}, ${tickets?.venue}`
                  }
                <span>Stadium Map</span>
              </p>
            </div>
            <div className="ticketSumCol2">
              <p>
                Select your match day package and choose the quantity of tickets
                you would like to buy.
              </p>
              <h2>
                Please note - seats will be allocated on a first come basis but
                your section will be secured. There will be stewards on match
                day to assist.
              </h2>
            </div>
          </div>

          <div className="selectTicketDetails">
            <h2>Select Ticket details to continue</h2>

            <form action="'">
              <div className="inputWrapT">
                <label htmlFor="">Select package type</label>
                <select
                  name=""
                  id=""
                  onChange={({ target }) => {
                    setPackage(target.value);
                    getGate(target.value);
                  }}
                >
                  <option value="">Select Package</option>
                  {ticketsCL.map((data) => {
                    return <option value={data._id}>{data.name}</option>;
                  })}
                </select>
              </div>

              <div className="inputWrapT">
                <label htmlFor="">Select gate</label>
                <select
                  name=""
                  id=""
                  onChange={({ target }) => setGate(target.value)}
                  disabled={packages == "" ? true : false}
                >
                  <option value="">Select gate</option>
                  {gates.map((data) => {
                    return <option>{data}</option>;
                  })}
                </select>
              </div>

              <div className="inputWrapT">
                <label htmlFor="">Select quantity</label>
                <select
                  name=""
                  onChange={({ target }) => setCount(target.value)}
                  id=""
                  disabled={gate == "" ? true : false}
                >
                  <option value="">Select quantity</option>
                  {[..."....."].map((res, index) => {
                    return <option value={index + 1}>{index + 1}</option>;
                  })}
                </select>
              </div>
            </form>
          </div>
          {gate == "" || packages == "" || count == "" ? (
            ""
          ) : (
            <div className="matchOverviewTable">
              <div className="matchOverviewTableHeader">
                <h2>Match</h2>
                <h2>Date</h2>
                <h2>Gate</h2>
                <h2>Tickets</h2>
                <h2>Package</h2>
                <h2>Price</h2>
              </div>

              <div
                className="matchOverviewTableHeader"
                style={{
                  borderBottom: "0",
                }}
              >
                <p>
                  {tickets.teams[0].name} VS {tickets.teams[1].name}
                </p>
                <p>{tickets.starts}</p>
                <p>{gate}</p>

                <p>{count}</p>
                <p>{packageName}</p>
                <p>₦{singleTicketAmount}</p>
              </div>

              <div className="paymentTotal">
                <div className="paymentTotalCol1">
                  <h2>Total</h2>
                  <h2>
                    {"₦"}
                    {count == 1
                      ? singleTicketAmount
                      : singleTicketAmount * count}
                  </h2>
                </div>

                <div className="paymentTotalbtn">
                  {user == null ? (
                    <button
                      onClick={() => {
                        setModal(true);
                      }}
                    >
                      Login to continue
                    </button>
                  ) : (
                    <PaystackButton
                      reference={new Date().getTime().toString()}
                      email={em}
                      amount={singleTicketAmount * 100 * count}
                      publicKey={process.env.REACT_APP_PAYSTACK_KEY}
                      text="PROCEED TO PAYMENT"
                      onSuccess={handleOnSuccess}
                      onClose={handleOnClose} // Optional
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <div> */}
      {/* <button onClick={onOpenModal}>Open modal</button> */}
      <ModalC open={open} onClose={onCloseModal} center>
        <div className="successModal">
          <h2>SUCCESS!!!</h2>
          <p>
            Your payment has been made successfully, and you can access it
            either on your email or your account
          </p>
          <button className="btn1" onClick={() => history.push(`/`)}>
            GO BACK HOME
          </button>
          <button className="btn2" onClick={() => history.push(`/profile`)}>
            GO TO ACOOUNT
          </button>
        </div>
      </ModalC>
      {/* </div> */}
    </div>
  );
};

export default Index;

import React from "react";

export default function Password({ password, setpassword, submitPassword }) {
  return (
    <div>
      <div className="header">
        <h2>Personal Information </h2>
      </div>

      <div className="userPWrap">
        <div className="userInputWrap">
          <input
            type="password"
            name=""
            id=""
            placeholder="Current password"
            onChange={({ target }) => {
              setpassword({ ...password, old_password: target.value });
              console.log(password);
            }}
            value={password.old_password}
          />
        </div>

        <div className="userInputWrap">
          <input
            type="password"
            name=""
            id=""
            placeholder="New password"
            onChange={({ target }) => {
              setpassword({ ...password, new_password: target.value });
              console.log(password);
            }}
            value={password.new_password}
          />
        </div>

        <div className="userInputWrap">
          <input
            type="password"
            name=""
            id=""
            placeholder="Confirm new password"
            onChange={({ target }) => {
              setpassword({ ...password, con_password: target.value });
              console.log(password);
            }}
            value={password.con_password}
          />
        </div>

        <div className="submitPbtn">
          <button onClick={submitPassword}>Save changes</button>
        </div>
      </div>
    </div>
  );
}

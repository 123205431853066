import React from "react";

export default function User({ setAuth, auth, submit }) {
  return (
    <div>
      <div className="header">
        <h2>Personal Information </h2>
      </div>

      <div className="userPWrap">
        <div className="userInputWrap">
          <input
            type="text"
            name=""
            id=""
            placeholder="First name"
            onChange={({ target }) => {
              setAuth({ ...auth, firstname: target.value });
              console.log(auth);
            }}
            value={auth.firstname}
          />
        </div>

        <div className="userInputWrap">
          <input
            type="text"
            name=""
            id=""
            placeholder="Last name"
            onChange={({ target }) => {
              setAuth({ ...auth, lastname: target.value });
              console.log(auth);
            }}
            value={auth.lastname}
          />
        </div>

        {/* <div className="userInputWrap">
          <input type="text" placeholder="Gender" />
        </div>

        <div className="userInputWrap">
          <input type="text" placeholder="Date of Birth" />
        </div>

        <div className="userInputWrap">
          <input type="text" placeholder="Password" />
        </div>

        <div className="userInputWrap">
          <input type="text" placeholder="Confirm Password" />
        </div> */}

        <div className="submitPbtn">
          <button onClick={submit}>Save changes</button>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import Banner from "../../components/banner/banner";
import club from "../../assets/images/club.png";
import "./index.scss";
import { useHistory } from "react-router-dom";
import { SidebarIcon1, SidebarIcon2 } from "../../assets/svg";
import User from "./user";
import Matches from "./matches";
import validateEmail from "../../components/validateEmail";
import validatePassword from "../../components/validatePassword";
import PasswordUpdate from "./passwordUpdate";
import { NotificationManager } from "react-notifications";
import { axiosCalls } from "../../components/_api";
import { hideLoader, showLoader } from "../../components/loader";
const Index = () => {
  const history = useHistory();
  const [tab, setTab] = useState("tab1");
  const [Tickets, setTickets] = useState([]);

  const [auth, setAuth] = useState({
    firstname: "",
    lastname: "",
  });

  const [password, setpassword] = useState({
    old_password: "",
    new_password: "",
    con_password: "",
  });
  const [appLoading, setAppLoading] = useState(false);

  useEffect(() => {
    makeCalls();
  }, []);

  const getTickets = () => {
    const res = axiosCalls("ticket", "GET");
    if (res) {
      if (res.er) {
        return;
      }
      return res;
    }
  };

  const makeCalls = async () => {
    showLoader();
    const [Tickets] = await Promise.all([getTickets()]);
    hideLoader();
    console.log(Tickets);
    if (Tickets.status == "success") {
      setTickets(Tickets?.payload);
    }
  };

  const submit = async (e) => {
    // alert(4);
    e.preventDefault();

    if (auth.firstname == "" || auth.lastname == "") {
      return NotificationManager.error("Error", "All fields are required");
    }

    setAppLoading(true);
    showLoader();
    const res = await axiosCalls("user", "PATCH", auth);
    hideLoader();
    if (res) {
      setAppLoading(false);
      console.log(res);
      if (res.er) {
        return;
      }
      console.log(res);
      setAuth({
        firstname: "",
        lastname: "",
      });

      return NotificationManager.success(res.message);
    }
  };

  const submitPassword = async (e) => {
    // alert(4);
    e.preventDefault();

    const password1 = validatePassword(password.new_password);
    if (password1 !== true) {
      setAppLoading(false);
      return NotificationManager.error("Error", password1);
    }

    const password2 = validatePassword(password.con_password);
    if (password2 !== true) {
      setAppLoading(false);
      return NotificationManager.error("Error", password2);
    }

    if (password.con_password !== password.new_password) {
      return NotificationManager.error(
        "Error",
        "Confirm password doese not match new password"
      );
    }

    if (password.old_password == "") {
      return NotificationManager.error("Error", "Old password is required");
    }

    setAppLoading(true);
    showLoader();
    const res = await axiosCalls("user", "PUT", password);
    hideLoader();
    if (res) {
      setAppLoading(false);
      console.log(res);
      if (res.er) {
        return;
      }
      console.log(res);
      setpassword({
        old_password: "",
        new_password: "",
        con_password: "",
      });

      return NotificationManager.success(res.message);
    }
  };

  return (
    <div>
      <Banner title="My Account" body="Update profile" />

      <div className="app-padding">
        <div className="profileLayout ">
          <div className="profileLayoutCol1">
            <div className="header">
              <h2>Menu</h2>
            </div>

            <ul>
              <li
                onClick={() => setTab("tab1")}
                style={tab == "tab1" ? { color: "#4dc937" } : {}}
              >
                <SidebarIcon1 color={tab == "tab1" ? "#4dc937" : "black"} />
                <span>Personal Information</span>{" "}
              </li>

              <li
                onClick={() => setTab("tab2")}
                style={tab == "tab2" ? { color: "#4dc937" } : {}}
              >
                <SidebarIcon1 color={tab == "tab2" ? "#4dc937" : "black"} />
                <span>Update Password</span>{" "}
              </li>

              <li
                onClick={() => setTab("tab3")}
                style={tab == "tab3" ? { color: "#4dc937" } : {}}
              >
                <SidebarIcon2 color={tab == "tab3" ? "#4dc937" : "black"} />
                <span>My Purchased Ticket</span>{" "}
              </li>
            </ul>
          </div>
          <div className="profileLayoutCol2">
            {tab == "tab1" ? (
              <User auth={auth} setAuth={setAuth} submit={submit} />
            ) : (
              ""
            )}
            {tab == "tab3" ? <Matches Tickets={Tickets} /> : ""}

            {tab == "tab2" ? (
              <PasswordUpdate
                password={password}
                setpassword={setpassword}
                submitPassword={submitPassword}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;

import React, { useEffect, useState } from "react";
import { Modal as ModalC } from "react-responsive-modal";
import "./index.scss";
import st from "../../assets/images/sta.png";
import lg from "../../assets/images/leg.png";
import imicon1 from "../../assets/images/cal.png";
import imicon2 from "../../assets/images/ball.png";
import imicon3 from "../../assets/images/ticket.png";
import imicon4 from "../../assets/images/location.png";
import cup from "../../assets/images/cup.webp";
import moretickets from "../../assets/images/m2.jpg";
import card1 from "../../assets/images/card1.jpg";
import card2 from "../../assets/images/card2.jpg";
// import club from "../../assets/images/club.png";
import { axiosCalls } from "../../components/_api";
import { useHistory } from "react-router-dom";
import { hideLoader, showLoader } from "../../components/loader";
import { AuthContext } from "../../context/AuthContext";

const FixtureCon = ({ games, history }) => {
  return games.map((data, index) => {
    return (
      <div className="matchCard" key={index}>
        <div className="matchCardChild">
          <div className="col1">
            <h2>{(new Date(data.starts)).toLocaleTimeString()}</h2>
          </div>

          <div className="col2">
            <img src={data.teams[0].logo} alt="" />
            <h2>VS</h2>
            <img src={data.teams[1].logo} alt="" />
          </div>

          {/* <div className="col3">
            <h2>$20.00</h2>
            <p>Tik Prize</p>
          </div> */}

          <div className="col4">
            <h2>{data?.venue}</h2>
          </div>

          {/* <div className="col5">
            <button
              onClick={() => {
                history.push(`/buy_ticket/${data._id}`);
              }}
            >
              Book Ticket
            </button>
          </div> */}
        </div>
      </div>
    );
  }); 
}
const HomePage = () => {
  const { user, setUser, setModal, Modal } = React.useContext(AuthContext);
  const history = useHistory();
  const [fixtures, setfixture] = useState([]);
  const [Mode, setMode] = useState("");
  const [open, setOpen] = useState(false);
  useEffect(() => {
    makeCalls();
  }, []);

  const getfixture = () => {
    const res = axiosCalls("fixture", "GET");
    if (res) {
      if (res.er) {
        return;
      }
      return res;
    }
  };

  const makeCalls = async () => {
    showLoader();
    const [fixture] = await Promise.all([getfixture()]);
    hideLoader();
    console.log(fixture);
    if (fixture.status == "success") {
      /** HotFix start */
      const groups = fixture?.payload?.reduce((groups, game) => {
        let d = new Date(game['starts']);
        const date = d.toDateString();
        if (!groups[date] && game.is_available) {
          groups[date] = [];
        }
        if (game.is_available) groups[date].push(game);
        return groups;
      }, {});
      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          games: groups[date]
        };
      });
      /** Hotfix ends */     

      // setfixture(fixture?.payload);
      setfixture(groupArrays); // HotFix
    }
  };

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  // alert("New updates");
  return (
    <>
      <div className="home-page">
        <div className="hero-content app-padding">
          <div className="textContent">
            <h2>
              FIBA World Cup
            </h2>
            <p>
              Olympic qualifiers -
            </p>
            {/* <button
              onClick={() =>
                history.push(`/buy_ticket/622dae66efb32350b20e22d3`)
              }
            >
              Buy ticket
            </button> */}
          </div>
        </div>
      </div>
      <div className="app-padding">
        <div className="showOff ">
          <div className="showOffCol1">
            <h2 className="h21">Upcoming Match</h2>
            <p className="p1">
              Get tickets for upcoming match and <br />
              tournament.
            </p>

            <div className="lga">
              <span>
                <p>FIBA Olympic 2023 qualifiers</p>
              </span>
            </div>

            <div className="matchTypeIcon">
              <div className="lgwrap">
                <img src={cup} alt="" />
              </div>
              <p>FIBA Olympic 2023 Qalifiers</p>
            </div>

            <div className="lgheaderde">
              <div>
                <img src={imicon1} alt="" />
                <p>Date</p>
              </div>

              <div>
                <img src={imicon2} alt="" />
                <p>Match</p>
              </div>

              <div>
                <img src={imicon3} alt="" />
                <p>Tickets</p>
              </div>

              <div>
                <img src={imicon4} alt="" />
                <p>Venue</p>
              </div>
            </div>

            {fixtures == 0
              ? ""
              : fixtures.map((d) => {
                // console.log('data', d)                
                return (<>
                  <p style={{ marginTop: 20, color: 'white' }}></p>
                  <div className="matchCard" style={{ marginBottom: 0 }} >
        <div className="matchCardChild">
          <div className="col1">
            <h2>{d.date}</h2>
          </div>

          <div className="col5">
            <button
              onClick={() => {
                history.push(`/buy_ticket/${d.games[0]._id}`);
              }}
            >
              Book Ticket
            </button>
          </div>
        </div>
      </div>
                  <FixtureCon games={d.games} history={history} />
                </>)
                 
                })}

            {/* <p
              onClick={() => {
                history.push(`/matches`);
              }}
              className="dmore"
            >
              Show all Matches
            </p> */}
          </div>
          <div className="showOffCol2">
            <img src={moretickets} alt="" />
          </div>
        </div>
      </div>
      <div className="clearPosition"></div>
      <h2 className="appHeader app-padding">Match information</h2>
      <div className="app-padding hovfsc">
        <div className="matchCardSlide ">
          <div
            className="card"
            onClick={() => {
              setMode("ticketLoca");
              onOpenModal();
            }}
          >
            <img src={card1} alt="" />
            <h2>Ticket Agents</h2>
            <p>
              Get your Tickets from the following physical locations in Abuja,
              Click here to view
            </p>
          </div>

          <div
            className="card"
            onClick={() => {
              setMode("probItem");
              onOpenModal();
            }}
          >
            <img src={card2} alt="" />
            <h2> Prohibited items</h2>
            <p>
              Please note the following items are prohibited , click to view
            </p>
          </div>
        </div>
      </div>
      <div className="app-padding">
        <div className="buyTickHome">
          {/* <h2>Buy Tickets</h2>
          <div>
            <button
              onClick={() => {
                history.push(`/matches`);
              }}
            >
              MEN’S FIXTURES
            </button>
            <button
              onClick={() => {
                history.push(`/matches`);
              }}
            >
              WOMEN’S FIXTURES
            </button>
            <button
              onClick={() => {
                history.push(`/matches`);
              }}
            >
              ACADEMY FIXTURES
            </button>
          </div> */}
        </div>
      </div>

      <ModalC open={open} onClose={onCloseModal} center>
        <div className="ticketInfoM">
          <h2 className="mdHeader">
            {Mode == "ticketLoca" ? "TICKETING LOCATIONS" : ""}
            {Mode == "probItem" ? "Prohibited items" : ""}
          </h2>
          {Mode == "ticketLoca" ? (
            <>
              <p>
                Apart from online purchase options, nairabox has partnered with
                a number of organisations to expand our physical ticketing
                presence. Below is a list of the locations where tickets can be
                purchased during office hours. Please note that tickets in these
                locations is subject to availability and that this list of
                partners can be updated from time to time. Tickets should only
                be purchased from the official agent ticket sellers listed
                below.
              </p>

              <p>
                Other websites should not be used to purchase tickets as you
                could be refused entry to the stadium or be ejected. Nairabox is
                not liable for any monies lost in such circumstances.
              </p>

              <ul>
                <li>The Fat Butcher</li>

                <li> Junkyard</li>
                <li> Downtown</li>
                <li>Keje Grills</li>
                <li>Abbey & Pan</li>
                <li>Genesis Cinemas</li>
                <li>Wakanow Offices</li>
              </ul>
            </>
          ) : (
            ""
          )}

          {Mode == "probItem" ? (
            <>
              <h4>What you can (and can’t) bring</h4>

              <p>
                Nothing matters more than your safety and the safety of others.
                That’s why we ask that only certain items are brought into the
                venue.
              </p>

              <p>
                Please make sure you’re not bringing anything you shouldn’t
                before arriving. Everyone’s checked before entry – we’d hate to
                have to turn you away.
              </p>

              <p>
                All bags must meet Bag Policy, although medical exemptions apply
                And any item which is deemed to be offensive or abusive,
                compromises public safety, or might be used as a weapon, will be
                confiscated and reported to the police.
              </p>

              <p>
                Here are some of the main points, but if you’re in any doubt,
                please ask upon entry.
              </p>

              <h4>You CAN'T bring?</h4>

              <ul>
                <li>
                  Bags which don’t meet Bag Policy (medical exemptions apply){" "}
                </li>
                <li>
                  {" "}
                  Aluminium and glass drink containers, plastic bottles over
                  500ml
                </li>
                <li> Alcohol </li>
                <li> Food and drink</li>
                <li> Pushchairs </li>
                <li> Weapons and tools </li>
                <li>
                  {" "}
                  Flares, fireworks, lasers, smoke devices and cannisters{" "}
                </li>
                <li> Large-bodied cameras and camcorders</li>
                <li> Drones </li>
                <li> Unauthorised musical instruments</li>
                <li>
                  {" "}
                  Signs or items with corporate or inappropriate branding
                </li>
                <li> Illegal substances</li>
                <li> Explosives or ammunition</li>
              </ul>

              <h4>You CAN bring?</h4>

              <ul>
                <li>Bags meeting our Bag Policy (medical exemptions apply)</li>
                <li>Small umbrellas</li>
                <li> Clear empty plastic bottles 500ml or less </li>
                <li> Small flags and banners (regulations apply) </li>
                <li> Pushchairs </li>
                <li> Crutches and walking aids </li>
              </ul>

              <p style={{ color: "orangered" }}>
                It’s worth keeping an eye on this page before every visit, as
                this list may change without notice.
              </p>
            </>
          ) : (
            ""
          )}

          <div className="closeBtn">
            <button onClick={onCloseModal}>Close</button>
          </div>
        </div>
      </ModalC>
    </>
  );
};

export default HomePage;

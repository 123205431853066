import React from "react";
import club from "../../assets/images/club.png";

export default function Matches({ Tickets }) {
  return (
    <div>
      <div className="matches ">
        <div className="header">
          <h2>MY PURCHASED TICKETS </h2>
        </div>
        <br />
        {Tickets.length == 0
          ? "No ticket found"
          : Tickets.map((data, index) => {
              return (
                <div
                  className="match-card"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    // history.push(`/buy_ticket/${data._id}`);
                  }}
                  key={index}
                >
                  <div className="match-card-header">
                    <div className="match-card-header-col1">
                      <h2>{data?.fixture?.starts}</h2>
                    </div>

                    <div className="match-card-header-col2">
                      <img src={data?.fixture?.teams[0]?.logo} alt="" />
                      <p>VS</p>
                      <img src={data?.fixture?.teams[1]?.logo} alt="" />
                    </div>

                    {/* <div className="match-card-header-col3">
                      <h2>$20.00</h2>
                      <p>Tik Prize</p>
                    </div> */}

                    <div className="match-card-header-col4">
                      <h2>{data?.venue}</h2>
                    </div>

                    <div className="match-card-header-col5">
                      <div>
                        <svg
                          width="24"
                          height="14"
                          viewBox="0 0 24 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.9999 13.8403C11.5698 13.8403 11.1397 13.6761 10.8118 13.3483L0.492339 3.02877C-0.164113 2.37232 -0.164113 1.30799 0.492339 0.651808C1.14853 -0.00437863 2.21264 -0.00437863 2.86914 0.651808L11.9999 9.78312L21.1308 0.652127C21.7872 -0.0040597 22.8512 -0.0040597 23.5074 0.652127C24.1641 1.30831 24.1641 2.37264 23.5074 3.02909L13.1881 13.3486C12.86 13.6765 12.4299 13.8403 11.9999 13.8403Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
}

import React from "react";
import "./banner.scss";

export default function Banner({ title, body }) {
  return (
    <div>
      <div className="bannerWrap app-padding">
        <h2>{title}</h2>
        <p>{body}</p>
      </div>
    </div>
  );
}

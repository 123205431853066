import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Logo } from "../assets/svg";

export default function Navbar({
  scrollY,
  showModal,
  setShowModal,
  setMode,
  mode,
  user,
  setModal,
}) {
  const history = useHistory();
  let path = history.location.pathname;
  const [tog, setTog] = useState(false);
  return (
    <div
      className={`landNavbarWrap app-padding ${scrollY == 0 ? "" : "addExtra"}`}
    >
      <div className="landNavbarcol1">
        <ul>
          <li
            onClick={() => {
              history.push(`/`);
              window.scrollTo(0, 0);
            }}
          >
            <Logo />
          </li>
          <li
            onClick={() => {
              history.push(`/`);
              window.scrollTo(0, 0);
            }}
            style={
              path == `/`
                ? {
                    color: "#4dc937",
                    textDecoration: "underline",
                    // fontSize: "16px",
                  }
                : {}
            }
            className="hd-mobile"
          >
            HOME
          </li>

          <li
            onClick={() => {
              history.push(`/matches`);
              window.scrollTo(0, 0);
            }}
            style={
              path == `/matches`
                ? {
                    color: "#4dc937",
                    textDecoration: "underline",
                    // fontSize: "16px",
                  }
                : {}
            }
            className="hd-mobile"
          >
            UPCOMING MATCHES
          </li>
        </ul>
      </div>

      <div className="landNavbarcol2">
        <div className="showMenu">
          <svg
            onClick={() => {
              setTog(!tog);
            }}
            fill="#000000"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width="23px"
            height="23px"
          >
            <path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z" />
          </svg>
        </div>
        <ul>
          {user == null ? (
            <li>
              <button
                onClick={() => {
                  setMode("login");
                  setShowModal(true);
                  setModal(true);
                  window.scrollTo(0, 0);
                }}
              >
                Login
              </button>
              <button
                onClick={() => {
                  setMode("register");
                  setShowModal(true);
                  setModal(true);
                  window.scrollTo(0, 0);
                }}
              >
                Register{" "}
              </button>
            </li>
          ) : (
            <li>
              <button
                onClick={() => {
                  history.push(`/profile`);
                  window.scrollTo(0, 0);
                }}
                style={{
                  width: "100%",
                }}
              >
                My Account
              </button>
            </li>
          )}
        </ul>
      </div>
      {tog == false ? (
        ""
      ) : (
        <div className="mobileShow">
          <ul>
            <li
              onClick={() => {
                history.push(`/`);
                window.scrollTo(0, 0);
                setTog(false);
              }}
              style={
                path == `/`
                  ? {
                      color: "#4dc937",
                      textDecoration: "underline",
                      // fontSize: "16px",
                    }
                  : {}
              }
            >
              HOME
            </li>

            <li
              onClick={() => {
                history.push(`/matches`);
                window.scrollTo(0, 0);
                setTog(false);
              }}
              style={
                path == `/matches`
                  ? {
                      color: "#4dc937",
                      textDecoration: "underline",
                      // fontSize: "16px",
                    }
                  : {}
              }
            >
              UPCOMING MATCHES
            </li>
            {user == null ? (
              <li>
                <button
                  onClick={() => {
                    setMode("login");
                    setShowModal(true);
                    setModal(true);
                    setTog(false);
                    window.scrollTo(0, 0);
                  }}
                >
                  Login
                </button>
                <button
                  onClick={() => {
                    setMode("register");
                    setShowModal(true);
                    setModal(true);
                    setTog(false);
                    window.scrollTo(0, 0);
                  }}
                >
                  Register{" "}
                </button>
              </li>
            ) : (
              <li>
                <button
                  onClick={() => {
                    history.push(`/profile`);
                    window.scrollTo(0, 0);
                    setTog(false);
                  }}
                  style={{
                    width: "100%",
                  }}
                >
                  My Account
                </button>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

import React from "react";
import { Logo, Logo2 } from "../assets/svg";
import FooterFlexSection from "./FooterFlexSection";

export default function Footer({ setModal, Modal, setMode }) {
  return (
    <div className="landingFooter">
      <div className="footerCol1 app-padding">
        <div className="footerCol1Child">
          <h2>
            Join the Football
            <br /> Community
          </h2>
          {/* <p>
            Join Now as a Nairabox Football Community Member <br />
            for exclusive Membership Benefits
          </p> */}
          <button
            style={{
              marginTop: "60px",
            }}
            onClick={() => {
              setModal(!Modal);
              setMode("register");
            }}
          >
            Join Now
          </button>
        </div>
      </div>
      <div className="footerCol2 app-padding">
        <Logo2 />
        <ul>
          <li>Contact Us</li>
          <li> Privacy Policy</li>
          <li> Terms & Conditions</li>
        </ul>
        <p className="copy-footer">
          &copy; 2022 Nairabox. All rights reserved. No part of this site may be
          reproduced without our written permission.
        </p>
      </div>
      {/* <FooterFlexSection />
      <p className="copy-footer">
        &copy; 2022 Chelsea FC. All rights reserved. No part of this site may be
        reproduced without our written permission.
      </p> */}
    </div>
  );
}

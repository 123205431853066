import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import OpenRoutes from "./layout/index";
import HomePage from "./pages/home/index";
import Match from "./pages/match";
import BuyTicket from "./pages/match/buyTicket";
import Profile from "./pages/account/index";
function Routes() {
  return (
    <Router>
      <Switch>
        <OpenRoutes exact path="/">
          <HomePage />
        </OpenRoutes>

        <OpenRoutes exact path="/matches">
          <Match />
        </OpenRoutes>

        <OpenRoutes exact path="/buy_ticket/:id">
          <BuyTicket />
        </OpenRoutes>

        <OpenRoutes exact path="/profile">
          <Profile />
        </OpenRoutes>
      </Switch>
    </Router>
  );
}

export default Routes;

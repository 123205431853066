import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Footer from "./footer";
import Navbar from "./navbar";
import Logo from "../assets/images/lol.png";
import "./index.scss";
import { Xicon } from "../assets/svg";
import countries from "../components/country";
import validateEmail from "../components/validateEmail";
import { NotificationManager } from "react-notifications";
import { axiosCalls } from "../components/_api";
import validatePassword from "../components/validatePassword";
import { AuthContext } from "../context/AuthContext";
import { hideLoader, showLoader } from "../components/loader";
export default function Index({ children }) {
  const { user, setUser, setModal, Modal } = useContext(AuthContext);
  const history = useHistory();
  const rootPath = history.location.pathname.split("/")[1];
  const [scrollY, setScrollY] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState("login");
  const [auth, setAuth] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    country: "Nigeria",
    phone: "",
    dob: "",
  });
  const [appLoading, setAppLoading] = useState(false);

  useEffect(() => {
    const onScroll = (e) => {
      console.log(window.scrollY);
      setScrollY(window.scrollY);
    };
    console.log(window.scrollY);
    window.addEventListener("scroll", onScroll);
    // window.scrollTo(0, 0);
  }, [window.scrollY]);

  const submit = async (e) => {
    // alert(4);
    e.preventDefault();
    if (mode == "register") {
      if (
        auth.firstname == "" ||
        auth.lastname == "" ||
        auth.country == "" ||
        auth.phone == "" ||
        auth.dob == ""
      ) {
        return NotificationManager.error("Error", "All fields are required");
      }
      const emailV = validateEmail(auth.email);
      const passwordV = validatePassword(auth.password);
      if (emailV !== true) {
        setAppLoading(false);
        return NotificationManager.error("Error", "Invalid Email");
      }

      if (passwordV !== true) {
        setAppLoading(false);
        return NotificationManager.error("Error", passwordV);
      }

      setAppLoading(true);
      showLoader();
      const res = await axiosCalls("user", "POST", auth);
      hideLoader();
      if (res) {
        setAppLoading(false);
        console.log(res);
        if (res.er) {
          return;
        }
        console.log(res);
        setAuth({
          firstname: "",
          lastname: "",
          email: "",
          password: "",
          country: "",
          phone: "",
          dob: "",
        });
        // setModal(false);
        setMode("login");
        return NotificationManager.success("Account created", "Please login");
      }
      return;
    }

    const emailV = validateEmail(auth.email);
    const passwordV = validatePassword(auth.password);
    if (emailV !== true) {
      setAppLoading(false);
      return NotificationManager.error("Error", "Invalid Email");
    }

    if (passwordV !== true) {
      setAppLoading(false);
      return NotificationManager.error("Error", passwordV);
    }

    setAppLoading(true);
    const data = {
      email: auth.email,
      password: auth.password,
    };
    showLoader();
    const res = await axiosCalls("auth", "POST", data);
    hideLoader();
    if (res) {
      setAppLoading(false);
      console.log(res);
      if (res.er) {
        return;
      }
      console.log(res);
      setAuth({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        country: "",
        phone: "",
        dob: "",
      });
      setModal(false);
      setUser(res.payload);
      localStorage.setItem("token", res.payload.access_token);
      localStorage.setItem("user", JSON.stringify(res.payload.user));

      NotificationManager.success("Success", res.message);

      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return;
    }
  };
  return (
    <div className="landingPage-wrap">
      <div className="landingP-navbar">
        <Navbar
          scrollY={window.scrollY}
          setShowModal={setShowModal}
          showModal={Modal}
          setMode={setMode}
          mode={mode}
          user={user}
          setModal={setModal}
        />

        <div className={` authModalWrap `}>
          <div
            className={`  ${
              Modal == true ? "authModalWrap1" : "hideAuthModal"
            }`}
          >
            <div
              className="closeModal"
              onClick={() => {
                // alert(Modal);
                setModal(!Modal);
              }}
            >
              <Xicon />
            </div>

            <div className="authHeader">
              <img src={Logo} alt="" />
              <h2>
                {mode == "login" ? "Login to continue" : `Create an account`}
              </h2>
              <p>
                Join now for free and be part of our fan community. and unlock
                new features{" "}
              </p>
              <p>{/* Don't have an account yet? <span>Join Us</span> */}</p>
            </div>

            <form action="" className="authFormModal">
              {mode == "login" ? (
                <>
                  {" "}
                  <div className="inputWrap">
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Email Address"
                      onChange={({ target }) => {
                        setAuth({ ...auth, email: target.value });
                        console.log(auth);
                      }}
                      value={auth.email}
                    />
                  </div>
                  <div className="inputWrap">
                    <input
                      type="password"
                      name=""
                      id=""
                      placeholder="Password"
                      onChange={({ target }) => {
                        setAuth({ ...auth, password: target.value });
                        console.log(auth);
                      }}
                      value={auth.password}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="inputWrap">
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="First name"
                      onChange={({ target }) => {
                        setAuth({ ...auth, firstname: target.value });
                        console.log(auth);
                      }}
                      value={auth.firstname}
                    />
                  </div>

                  <div className="inputWrap">
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Last name"
                      onChange={({ target }) => {
                        setAuth({ ...auth, lastname: target.value });
                        console.log(auth);
                      }}
                      value={auth.lastname}
                    />
                  </div>

                  <div className="inputWrap">
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Email Address"
                      onChange={({ target }) => {
                        setAuth({ ...auth, email: target.value });
                        console.log(auth);
                      }}
                      value={auth.email}
                    />
                  </div>

                  <div className="inputWrap">
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Mobile number"
                      onChange={({ target }) => {
                        setAuth({ ...auth, phone: target.value });
                        console.log(auth);
                      }}
                      value={auth.phone}
                    />
                  </div>

                  <div className="inputWrap">
                    <input
                      type="password"
                      name=""
                      id=""
                      placeholder="Password"
                      onChange={({ target }) => {
                        setAuth({ ...auth, password: target.value });
                        console.log(auth);
                      }}
                      value={auth.password}
                    />
                  </div>

                  <div className="inputWrap">
                    <input
                      type="date"
                      name=""
                      id=""
                      placeholder="Date of Birth"
                      onChange={({ target }) => {
                        setAuth({ ...auth, dob: target.value });
                        console.log(auth);
                      }}
                      value={auth.dob}
                    />
                  </div>

                  <div className="inputWrap">
                    <select
                      onChange={({ target }) => {
                        setAuth({ ...auth, country: target.value });
                        console.log(auth);
                      }}
                      value={auth.country}
                    >
                      <option value="">Country/Region</option>
                      {countries.map((data) => {
                        return <option value={data.name}>{data.name}</option>;
                      })}
                    </select>
                  </div>
                </>
              )}

              <div className="optionsauth">
                {mode == "login" ? (
                  <p
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setMode("register")}
                  >
                    Dont have an account? <span>Signup</span>
                  </p>
                ) : (
                  <p
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setMode("login")}
                  >
                    Already have an account? <span>SignIn</span>
                  </p>
                )}
              </div>

              <div className="handleModalSubmit">
                <button onClick={submit}>
                  {mode == "login" ? `Login` : "Create account"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className="landingP-main"
        style={
          rootPath == "our_work" ||
          rootPath == "read_more" ||
          rootPath == "career" ||
          rootPath == "join_us"
            ? {
                background: "#f7ffff",
              }
            : rootPath == "about_us" || rootPath == "blog"
            ? { background: "#F8F5FF" }
            : {}
        }
      >
        {children}

        <div className="landingP-footer">
          <Footer setModal={setModal} Modal={Modal} setMode={setMode} />
        </div>
      </div>
    </div>
  );
}

import React, { createContext, useEffect, useState } from "react";

// import {useNavigation} from '@react-navigation/native';

export const AuthContext = createContext();

export const AuthContextProvider = (props) => {
  const [user, setUser] = useState(null);
  const [Modal, setModal] = useState(false);
  useEffect(() => {
    // validateUser();
    console.log(user);
    getUserFromStorage();
  }, []);

  const getUserFromStorage = () => {
    let lUser = localStorage.getItem("user");
    let parseUser = JSON.parse(lUser);
    if (parseUser == null || parseUser == "" || parseUser == undefined) {
      setUser(null);
    } else {
      setUser(parseUser);
    }
  };

  // useEffect(() => {
  //   if (recallLocal == true) {
  //     validateUser();
  //   }
  // }, [recallLocal]);

  // const logout = () => {
  //   clearLocalStore();
  //   setUser({});
  // };

  // const validateUser = async () => {
  //   // clearLocalStore();
  //   const Localtoken = await getLocalData('token');
  //   if (Localtoken == null || Localtoken == undefined) {
  //     setUserAuth(false);
  //     setFirstVisit(true);
  //     return;
  //   }

  //   setFirstVisit(false);
  //   getUser();
  //   setToken(token);
  //   setUserAuth(true);
  //   getUserApi('reroute');
  //   console.log(token);
  // };

  // const getUser = async () => {
  //   const getLocalUser = await getLocalData('user');

  //   console.log(getLocalUser);
  //   setUser(getLocalUser);
  //   // setLoadingProfile(false);

  //   getUserApi();
  // };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        setModal,
        Modal,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
